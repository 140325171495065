import { EDIT_USER_DATA, SAVE_USER_DATA, USER_LOGOUT } from "../actions/types";

/**
 * user reducer
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export function UserReducer(state = {}, action) {
  const { type, payload } = action;

  switch (type) {
    case SAVE_USER_DATA:
      return {
        ...state,
        user: payload.user,
        token: payload.token,
        type: payload.type,
        session_expired: false,
      };
    case EDIT_USER_DATA:

      return {
        ...state,
        user: payload.user,
      };
    case sessionStorage:
      return {
        ...state,
        session_expired: true,
      };
    case USER_LOGOUT:
      state = {};
      return {
        ...state,
      };

    default:
      return state;
  }
}

