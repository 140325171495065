import * as axios from "../network/Network";

const config = require("../network/api.json");

class StandardService {
    /**
     * online
     * @returns
     */
    static online() {
        return axios.appRequest({
            method: axios.GET,
            url: config.user.online,
        });
    }

    /**
     * get room info
     * @param {*} id
     * @returns
     */
    static getRoomInfo(id) {
        return axios.appRequest({
            method: axios.GET,
            url: config.user.getRoomInfo + `/${id}`,
        });
    }
    /**
      * get room info
      * @param {*} id
      *  @param {*} arrival_date
      * @param {*} departure_date
      * @returns
      */
    static getSingleRoomInfo(id, arrival_date, departure_date) {
        return axios.appRequest({
            method: axios.GET,
            url:
                config.user.getSingleRoomInfo +
                `/${id}?arrival_date=${arrival_date}&departure_date=${departure_date}`,
        });
    }
    /**
     * onlinebook1
     * @param {*} arrival_date
     * @param {*} arrival_time
     * @param {*} departure_date
     * @param {*} departure_time
     * @param {*} adults
     * @param {*} children
     * @param {*} room_type
     * @param {*} no_of_room
     * @param {*} room_charge
     * @param {*} additional_charge
     * @param {*} total_price
     * @returns
     */
    static onlinebook1(
        arrival_date,
        arrival_time,
        departure_date,
        departure_time,
        adults,
        children,
        room_type,
        no_of_room,
        room_charge,
        additional_charge,
        total_price,
        total_length,
        name,
        phone,
        timediff,
        diffDate,
        servId,
        totalservice,
        extra_occupancy,
        single_occupancy_price,
        taxdata,
        no_of_occupancy,
        single_occupaid,
        extra_occupaid,
        tax_amount
    ) {
        return axios.appRequest({
            method: axios.POST,
            url: config.user.onlinebook1,
            data: {
                arrival_date: arrival_date,
                arrival_time: arrival_time,
                departure_date: departure_date,
                departure_time: departure_time,
                adults: Number(adults),
                children: Number(children),
                room_type: room_type,
                no_of_room: Number(no_of_room),
                room_charge: room_charge,
                additional_charge: additional_charge,
                total_price: total_price,
                total_length,
                name,
                phone,
                timediff,
                diffDate,
                servId,
                totalservice,
                extra_occupancy,
                single_occupancy_price,
                taxdata,
                no_of_occupancy,
                single_occupaid,
                extra_occupaid,
                tax_amount
            },
        });
    }
}
export { StandardService };
