import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions } from "../../../actions";
import { userLogout } from "../../../actions/UserAction";
import { UserService } from "../../../services";

const useTopBar = () => {
     const [locationData, setlocationData] = useState("");
     const navigate = useNavigate();
     const [locInfo, setLocInfo] = useState("");
     const dispatch = useDispatch();
     const [openAccount, setOpenAccount] = useState(false);
     const showAccount = () => {
          setOpenAccount(!openAccount);
     }

     /**
      * get place
      */
     useEffect(() => {
          getPlaceList()
     }, []);

     /**
      * get place list
      */
     const getPlaceList = () => {
          // UserService.place_list().then((res) => {
          //      setlocationData(res?.data?.data?.rooms)

          // }).catch((err) => console.log(err))

     }
     /**
      * change select
      * @param {*} e 
      */
     const changeSelect = (e) => {
          var id = JSON.parse(e.target.value);
          setLocInfo(id?.rooms_type?.[0]?.location);
          navigate("/", { state: { id: id?._id, loc: id?.rooms_type?.[0]?.location } })
     }

     /**
      * loin redirect
      */
     const loginRedirect = () => {
          navigate("/Login");
     }


     const navigateToMyAccount = () => {
          navigate("/myAccount")
     }

     /**
      * handle logout
      */
     const handleLogout = () => {
          dispatch(userLogout());
          navigate("/");
          dispatch(alertActions.success("Logout Success"));
     }
     /**
      * go to home
      */
     const gotoHome = () => {
          localStorage.removeItem("datetime1")
          localStorage.removeItem("datetime2")
          localStorage.removeItem("datetimes")
          localStorage.removeItem("datetimes1")
          navigate("/")
     }

     return {
          gotoHome, locationData, locInfo, openAccount, showAccount, changeSelect, loginRedirect, navigateToMyAccount, handleLogout
     }
}
export default useTopBar
