import { MyAccountService, UserService } from "../services";
import { EDIT_USER_DATA, SAVE_USER_DATA, SESSION_EXPIRE, USER_LOGOUT } from "./types";

/**
 * user login
 * @param {*} phone 
 * @param {*} otp 
 * @returns 
 */
export const userLogin = (phone, otp) => async (dispatch) => {
    const data = await UserService.login(phone, otp);

    if (data?.data?.status?.code === 0) {
        dispatch({
            type: SAVE_USER_DATA,
            payload: {
                token: data?.data?.data?.access_token,
                user: data?.data?.data?.user,
                type: data?.data?.data?.token_type,
            },
        });
    }
    return await Promise.resolve(data);
};

/**
 * update profile
 * @param {*} name 
 * @param {*} email 
 * @param {*} phone 
 * @param {*} id 
 * @returns 
 */
export const updateProfile = (name, email, phone, id) => async (dispatch) => {
    const data = await MyAccountService.updatemyAccountProfile(name, email, phone, id);

    if (data?.data?.status?.code === 0) {
        dispatch({
            type: EDIT_USER_DATA,
            payload: {
                user: data?.data?.data
            },
        });

    }
    return await Promise.resolve(data);
};

/**
 * update user
 * @param {*} phone 
 * @returns 
 */
export const updateUser = (phone) => async (dispatch) => {
    const data = await UserService.verifyNumber(phone);

    if (data?.data?.status?.code === 0) {
        dispatch({
            type: SAVE_USER_DATA,
            payload: {
                token: data?.data?.data?.access_token,
                user: data?.data?.data?.user,
                type: data?.data?.data?.token_type,
            },
        });

    }
    return await Promise.resolve(data);
};

/**
 * Registration
 * @param {*} name 
 * @param {*} email 
 * @param {*} phone 
 * @param {*} otp 
 * @returns 
 */
export const registration = (name, email, phone, otp) => async (dispatch) => {
    const data = await UserService.registration(name, email, phone, otp);

    if (data?.data?.status?.code === 0) {
        dispatch({
            type: SAVE_USER_DATA,
            payload: {
                token: data?.data?.data?.access_token,
                user: data?.data?.data?.user,
                type: data?.data?.data?.token_type,
            },
        });
    }
    return await Promise.resolve(data);
};


/**
 * User logout
 * @returns 
 */
export function userLogout() {
    return function (dispatch) {
        const action = {
            type: USER_LOGOUT,
        };
        localStorage.clear();

        dispatch(action);
    };
}
/**
 * Session expire
 * @param {*} dispatch 
 * @returns 
 */
export const sessionExpire = (dispatch) => {
    dispatch({
        type: SESSION_EXPIRE,
    });
    return;
};
