import * as axios from "../network/Network";

const config = require("../network/api.json");

class HomeService {
  /**
   * room list
   * @returns
   */
  static roomlist() {
    return axios.appRequest({
      method: axios.GET,
      url: config.user.roomlist,
    });
  }
  static getRoomList(arrival_date, departure_date) {
    return axios.appRequest({
      method: axios.GET,
      url:
        config.user.getRoomList +
        `?arrival_date=${arrival_date}&departure_date=${departure_date}`,
    });
  }
}
export { HomeService };
