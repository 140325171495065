import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { alertActions } from "../../../actions";
import { updateProfile } from "../../../actions/UserAction";
import { MyAccountService } from "../../../services"


const useMyAccount = (t) => {
    const [mobile_no, setMobile_no] = useState("");
    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const user_id = useSelector((state) => state?.userData?.user?._id);
    const phone = useSelector((state) => state?.userData?.user?.phone);
    const [submit, setSubmit] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [loader, setLoader] = useState("notset");
    const [loading, setLoading] = useState("notset");
    const [editing, setEditing] = useState(false);
    const [current, setcurrent] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    useEffect(() => {
        getMyDetails();
        getBookingHistory();
        // eslint-disable-next-line
    }, [current]);

    /**
     * get my details
     */
    const getMyDetails = () => {
        setLoader("loading");
        MyAccountService.getMyAccountDetails(user_id)
            .then((res) => {
                if (res?.data?.status?.code === 0) {
                    setMobile_no(res?.data?.data?.[0]?.phone);
                    setEmail(res?.data?.data?.[0]?.email);
                    setFullName(res?.data?.data?.[0]?.name);
                    setLoader("isfull");
                }

            }).catch((error) => {
                console.log("Errorreg", error);
            });

    }

    /**
     * get booking history
     */
    const getBookingHistory = () => {
        setLoading("loading");
        MyAccountService.getBookingHistory(phone, current)
            .then((res) => {
                if (res?.data?.status?.code === 0) {
                    setPageCount(res?.data?.data?.totalPages);
                    setHistoryData(res?.data);
                    setLoading("isfull");
                }

            }).catch((error) => {
                console.log("Errorreg", error);
            });

    }

    /**
     * page change
     * @param {} e 
     */
    const changeCurrentPage = (page) => {
        setcurrent(page.selected + 1);
    };
    /**
     * handle change
     * @param {*} e 
     */
    const handleChangeName = (e) => {
        setFullName(e.target.value)
    }
    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    /**
     * navigate to booking
     */
    const navigateToBook = () => {
        navigate("/")
    }

    const handleUpdateStatus = (e) => {
        setEditing(true);
    }

    /**
     * handle edit profile
     * @param {*} e 
     */
    const handleEditProfile = (e) => {
        e.preventDefault();
        var pattern = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        setSubmit(true);
        if (fullName && email && mobile_no && user_id) {
            if (email && !pattern.test(email)) {
                dispatch(alertActions.error(t("Enter_valid_Email_Address")));
            }else{
            setLoader("loading");
            dispatch(updateProfile(fullName, email, mobile_no, user_id))
                .then((response) => {
                    if (response?.data?.status?.code === 0) {
                        setSubmit(false);
                        setEditing(false);
                        setLoader("isfull");
                        dispatch(alertActions.success(response?.data?.status?.message));
                    }
                    else {
                        setLoader("isempty");
                        dispatch(alertActions.error(response?.data?.status?.message));
                    }

                })
                .catch((error) => {
                    console.log("Errorreg", error);
                });
            }
            
        }
    
    }
    const changedate = (date1, date2) => {
        let arr = date1.split("-");
        let arr2 = new Date(arr[0], arr[1] - 1, arr[2]);
        let arr1 = date2.split("-");
        let arr4 = new Date(arr1[0], arr1[1] - 1, arr1[2]);
        let diffInMs = Math.abs(arr4 - arr2);
        return ((diffInMs / (1000 * 60 * 60 * 24)));
    }
    return {

        mobile_no,
        fullName,
        email,
        submit,
        historyData,
        loader,
        loading,
        editing,
        pageCount,
        current,
        changedate,
        navigateToBook,
        handleChangeName,
        handleChangeEmail,
        handleEditProfile,
        handleUpdateStatus,
        changeCurrentPage
    }


}
export default useMyAccount