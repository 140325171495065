import * as axios from "../network/Network";

const config = require("../network/api.json");

class UserService {
  /**
   * send otp
   * @param {*} name 
   * @param {*} email 
   * @param {*} phone 
   * @returns 
   */
  static send_otp(name, email, phone) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.register,
      data: {
        name: name,
        email: email,
        phone: phone,
      },
    });
  }

  /**
   * verify no
   * @param {*} phone 
   * @returns 
   */
  static verifyNumber(phone) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.number_verify,
      data: {
        phone: phone,
      },
    });
  }
  /**
  * Login
  * @param {*} phone 
  * @param {*} otp 
  * @returns 
  */
  static login(phone, otp) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.login,
      data: { phone, otp },
    });
  }

  /**
 * Registration
 * @param {*} phone 
 * @param {*} otp 
 * @returns 
 */
  static registration(name, email, phone, otp) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.registration_login,
      data: { name, email, phone, otp },
    });
  }



  /**
   * send otp
   * @param {*} phone 
   * @returns 
   */
  static send_otp_login(phone) {
    return axios.appRequest({
      method: axios.POST,
      url: config.user.sendotp,
      data: {
        phone: phone,
      },
    });
  }


  /**
   * get room by id
   * @param {*} id 
   * @returns 
   */
  static getRoombyId(id) {
    return axios.appRequest({
      method: axios.GET,
      url: config.user.roombyId + `/${id}`,
    });
  }

  /**
   * list amentities
   * @returns 
   */
  static listamenities() {
    return axios.appRequest({
      method: axios.GET,
      url: config.user.listamenities,
    });
  }


  /**
   * 
   * @returns place list
   */
  static place_list() {
    return axios.appRequest({
      method: axios.GET,
      url: config.user.place_list,
    });
  }



}
export { UserService };
