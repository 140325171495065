import * as axios from "../network/Network";

const config = require("../network/api.json");

class MyAccountService {

    /**
     * get my account details
     * @param {*} id 
     * @returns 
     */
    static getMyAccountDetails(id) {
        return axios.appRequest({
            method: axios.GET,
            url: config.user.myAccountDetails + `/${id}`,
        });
    }
    /**
     * update account profile
     * @param {*} name 
     * @param {*} email 
     * @param {*} phone 
     * @param {*} id 
     * @returns 
     */
    static updatemyAccountProfile(name, email, phone, id) {
        return axios.appRequest({
            method: axios.POST,
            url: config.user.updateAccountDetails + `/${id}`,
            data: { name, email, phone },
        });
    }

    /**
     * get booking history
     * @returns 
     */
    static getBookingHistory(phone, page) {
        return axios.appRequest({
            method: axios.GET,
            url: config.user.bookingHistory + `?phone=${phone}&page=${page}`,
        });
    }

}
export { MyAccountService };
