
function commaValidation(number) {
    var amount =
        typeof number === "string"
            ? Number(parseFloat(number.replace(/,/g, "")))
            : Number(number)
        ;

    return amount.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
}
export { commaValidation };